<script type="ts">
  export let size = 16;
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  class="inline-block animate-spin"
  width={size}
  height={size}
  viewBox="0 0 8.467 8.467"
>
  <path d="M4.233 0a4.233 4.233 0 0 0-2.986 1.247l.742.742a3.175 3.175 0 0 1 2.244-.93 3.175 3.175 0 0 1 2.245.93l.748-.748A4.233 4.233 0 0 0 4.233 0Z" style="fill:white"/>
</svg>
